<template>
  <v-bottom-navigation
    v-model="bottomNav"
    background-color="#FBE8DC"
    :fixed="true"
    color="secondary"
    class="footer-navbar justify-space-between"
    height="65"
  >
    <v-btn :to="{ name: 'ngo.runs' }" :ripple="false" depressed>
      <img src="/images/my-runs.svg">
      <span>Our runs</span>
    </v-btn>

    <v-btn :to="{ name: 'ngo.drop-offs' }" :ripple="false" depressed>
      <img src="/images/history.svg">
      <span>Drop offs</span>
    </v-btn>

    <v-btn :to="{ name: 'ngo.volunteers' }" :ripple="false" depressed>
      <img src="/images/volunteers.svg">
      <span>Volunteers</span>
    </v-btn>

    <v-btn depressed to="/faq" :ripple="false">
      <img src="/images/how-to.svg">
      <span>FAQ</span>
    </v-btn>

    <v-btn depressed small to="/profile" :ripple="false">
      <img src="/images/me.svg">
      <span>Account</span>
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="315"
      content-class="delete-dialog"
    >
      <v-card>
        <v-card-title class="headline text-center">Delete {{ $store.state.selectedRuns.length }} run from your list?</v-card-title>
        <v-card-actions>
          <v-btn
            class="modal-btn cancel"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            class="modal-btn ok"
            text
            @click="deleteRuns"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-bottom-navigation>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      runs: [],
      bottomNav: null
    };
  },
  mounted () {
    this.$bus.$on('runs-deleted', (runs) => {
      this.runs = runs;
    });
  },
  methods: {
    deleteRuns () {
      this.$bus.$emit('runs-deleted');
      this.dialog = false;
    }
  }
};
</script>
