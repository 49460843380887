import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en.json';
import zh from './zh.json';

Vue.use(VueI18n);

const messages = {
  en,
  zh
};

const dateTimeFormats = {
  'en': {
    moye: {
      month: 'long',
      year: 'numeric'
    },
    damo: {
      day: 'numeric',
      month: 'short'
    },
    long: {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    }
  },
  'zh': {
    moye: {
      year: 'numeric',
      month: 'short'
    },
    damo: {
      day: 'numeric',
      month: 'short'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short'
    }
  }
};

const i18n = new VueI18n({
  locale: 'en',
  dateTimeFormats,
  messages
});

export default i18n;
