import { userTokenStorageKey } from '@/config';
import { http } from '@/plugins/http/index';
import * as userService from '@/services/user';
import { isEmpty } from 'lodash';
import { setToken as httpSetToken } from '@/plugins/http';
import vm from '@/main';

/**
 * @param dispatch
 * @param provider
 * @param data
 * @returns {*}
 */
export const socialLogin = ({ dispatch }, { provider, data }) => {
  return http.post('/auth/' + provider + '/callback', data)
    .then((response) => {
      return dispatch('setToken', response.data.data[0]) // keep promise chain
        .then(async () => {
          await dispatch('loadUser');
        });
    });
};

/**
 * @param dispatch
 * @param phone
 * @param provider
 * @returns {*}
 */
export const donorLogin = ({ dispatch }, phone) => {
  return http.post('/auth/donor', phone, {
    headers: {
      'Content-Type': 'text/plain; charset=utf-8'
    }
  })
    .then((response) => {
      return dispatch('setToken', response.data.data[0]) // keep promise chain
        .then(async () => {
          await dispatch('loadUser');
        });
    });
};

export const groupLogin = ({ dispatch }, encrypted) => {
  return http.post('/auth/group', encrypted, {
    headers: {
      'Content-Type': 'text/plain; charset=utf-8'
    }
  })
    .then((response) => {
      return dispatch('setToken', response.data.data[0]) // keep promise chain
        .then(async () => {
          await dispatch('loadUser');
        });
    });
};

/**
 * User Logout
 *
 * @param dispatch
 * @param commit
 * @returns {Promise.<*[]>}
 */
export const logout = ({ dispatch, commit }) => {
  // userService.revokeToken()
  dispatch('setToken', '');
  commit('logout');
  return new Promise(resolve => {
    resolve(vm.$router.push('/welcome'));
  });
};

/**
 * Set user in Vuex
 *
 * @param commit
 * @param user
 */
export const setUser = ({ commit }, user) => {
  // Commit the mutations
  commit('setUser', user);

  Promise.resolve(user); // keep promise chain
};

/**
 * Set token into storage
 *
 * @param commit
 * @param payload
 * @returns {Promise.<T>}
 */
export const setToken = ({ commit }, payload) => {
  // prevent if payload is a object
  const token = (isEmpty(payload)) ? null : payload.token || payload;

  // Commit the mutations
  commit('setToken', token);
  httpSetToken(token);
  localStorage.setItem(userTokenStorageKey, token || '');

  return Promise.resolve(token); // keep promise chain
};

/**
 * Check user token
 *
 * @param dispatch
 * @param state
 * @returns {Promise.<T>}
 */
export const checkUserToken = ({ dispatch, state }) => {
  // If the token exists then all validation has already been done
  if (state.auth.token) {
    return Promise.resolve(state.auth.token);
  }

  /**
   * Token does not exist yet
   * - Recover it from localstorage
   * - Recover also the user, validating the token also
   */
  const token = localStorage.getItem(userTokenStorageKey);

  if (isEmpty(token)) {
    return Promise.reject(new Error('NO_TOKEN'));
  }
  // Put the token in the vuex store
  return dispatch('setToken', token) // keep promise chain
    .then((token) => {
      return dispatch('loadUser').then(() => token);
    });
};

/**
 * Retrieves updated user information
 * If something goes wrong, the user's token is probably invalid
 */
export const loadUser = async ({ dispatch, state }) => {
  let userData = await userService.me().catch(() => {
    dispatch('setToken', '');
    return Promise.reject(new Error('FAIL_IN_LOAD_USER'));
  });
  dispatch('setUser', userData);
  if ((userData.role === 'volunteer' || userData.role === 'leader') && !userData.welcomeCompleted) {
    await Promise.reject(vm.$router.push('/phone'));
  } else {
    dispatch('initStore');
  }
};

/**
 * @param commit
 * @param dispatch
 * @returns {Promise<*>}
 */
export const initStore = ({ commit, dispatch }) => {
  dispatch('loadRuns');
  if (vm.$store.getters.isVolunteer || vm.$store.getters.isLeader) {
    dispatch('loadStats');
  }
  if (vm.$store.getters.isNgo) {
    dispatch('loadStats');
    dispatch('dropoffs/get');
  }

  if (!vm.$store.getters.isDonor) {
    dispatch('loadNgos');
  }

  return commit('setInitialized');
};

/**
 * Load user's ngos
 *
 * @param dispatch
 * @param commit
 * @returns {Promise<any> | PromiseLike<any>}
 */
export const loadNgos = async ({ commit }) => {
  let response = await userService.ngos();
  commit('setNgos', response);
};

/**
 * Load user's ngos
 *
 * @param dispatch
 * @param commit
 * @returns {Promise<any> | PromiseLike<any>}
 */
export const loadRuns = ({ commit }) => {
  userService.runs()
    .then(response => commit('setRuns', response));
};

/**
 * Load user's ngos
 *
 * @param dispatch
 * @returns {Promise<any> | PromiseLike<any>}
 */
export const loadStats = ({ commit }) => userService.stats()
  .then(response => {
    commit('setStats', response);
  });
