import * as config from '@/config';

export default {
  computed: {
    $config () {
      return config;
    },
    isLogged () {
      return this.$store.getters.logged;
    },
    currentUser () {
      return this.$store.getters.currentUser;
    }
  },
  created: function () {
    if (localStorage.locale) {
      this.$i18n.locale = localStorage.locale;
    } else {
      localStorage.locale = 'en';
    }
  },
  methods: {
    clone (object) {
      return JSON.parse(JSON.stringify(object));
    }
  }
};
