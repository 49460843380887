import * as donorService from '@/services/donor';

export default {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    async get ({ commit }) {
      commit('setDonors', await donorService.get());
    }
  },
  mutations: {
    setDonors (state, donors) {
      state.all = donors;
    }
  },
  getters: {
    count (state) {
      return state.all.count;
    }
  }
};
