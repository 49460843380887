// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const me = () => http.get('/me').then(getData);

export const ngos = () => http.get('/me/ngos').then(getData);

export const updateNgos = (ngos) => http.put('/me/ngos', { ngo: ngos }).then(getData);

export const updatePhone = (e164) => http.put('/me/phone', { phone: e164 }).then(getData);

export const stats = () => http.get('/me/stats').then(getData);

export const runs = (params) => http.get('/me/runs', { params }).then(getData);

/**
 * Revoke current token
 *
 * @returns {Promise<T>}
 */
export const revokeToken = () => http.post('/auth/token/revoke').then(getData);
