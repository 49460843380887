import store from '../store';
import middlewarePipeline from './middlewarePipeline';

const beforeEach = (to, from, next) => {
  store.dispatch('checkUserToken')
    .finally(() => {
      if (!to.meta.middleware) {
        return next();
      }

      // run middleware
      const middleware = to.meta.middleware;
      const context = { to, from, next, store };
      return middleware[0]({
        ...context,
        nextMiddleware: middlewarePipeline(context, middleware, 1)
      });
    });
};

export default beforeEach;
