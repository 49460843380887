<template>
  <v-bottom-navigation
    v-model="bottomNav"
    background-color="#FBE8DC"
    :fixed="true"
    color="secondary"
    class="footer-navbar justify-space-between"
    height="65"
  >
    <v-btn exact :to="{ name: 'admin.runs' }" :ripple="false" depressed>
      <img src="/images/my-runs.svg">
      <span>Runs</span>
    </v-btn>
    <v-btn exact :to="{ name: 'admin.volunteers' }" :ripple="false" depressed>
      <img src="/images/volunteers.svg">
      <span>Volunteers</span>
    </v-btn>
    <v-btn @click="$store.dispatch('logout')" depressed small active-class="no-active">
      <img src="/images/me.svg">
      <span>LOG OUT</span>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      runs: [],
      bottomNav: null
    };
  },
  mounted () {
    this.$bus.$on('runs-deleted', (runs) => {
      this.runs = runs;
    });
  },
  methods: {
    deleteRuns () {
      this.$bus.$emit('runs-deleted');
      this.dialog = false;
    }
  }
};
</script>

<style>
.no-active {
  border-bottom-color: transparent !important;
}
</style>
