import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FF714D',
        light_primary: '#FBE8DC',
        background_primary: '#FCF1EA',
        secondary: '#0A3F60',
        lighten_primary: '#FCF1EA',
        aqua: '#93D4D6'
      }
    }
  }
});
