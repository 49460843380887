import vm from '@/main';

export const logged = ({ auth }) => auth.isAuthorized;

export const currentUser = ({ auth }) => auth.user;

export const isAdmin = ({ auth }) => auth.user.role === 'admin';

export const isNgo = ({ auth }) => auth.user.role === 'ngo';

export const isDonor = ({ auth }) => auth.user.role === 'donor';

export const isGroup = ({ auth }) => auth.user.role === 'group';

export const isVolunteer = ({ auth }) => auth.user.role === 'volunteer';

export const isLeader = ({ auth }) => auth.user.role === 'leader';

export const isRunRemoving = ({ removingRuns }) => removingRuns;

export const myNgos = ({ auth }) => auth.ngos;

export const myRuns = ({ auth }) => auth.runs;

export const todayRuns = ({ auth }) => auth.runs.filter(run => run.date === vm.$moment().format('YYYY-MM-DD'));
