import * as ngoService from '@/services/ngo';

export default {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    async get ({ commit }) {
      commit('setNgos', await ngoService.get());
    }
  },
  mutations: {
    setNgos (state, ngos) {
      state.all = ngos;
    }
  },
  getters: {
    count (state) {
      return state.all.count;
    }
  }
};
