import Vue from 'vue';
import VueRouter from 'vue-router';
import beforeEach from './beforeEach';

import guest from './middlewares/guest';
import auth from './middlewares/auth';
import admin from './middlewares/admin';
import notAdmin from './middlewares/not-admin';
import ngo from './middlewares/ngo';
import notNgo from './middlewares/not-ngo';
import notDonor from '@/router/middlewares/not-donor';
import notGroup from '@/router/middlewares/not-group';
import donor from '@/router/middlewares/donor';
import Logout from '../app/pages/logout';

export const Welcome = r => require.ensure([], () => r(require('../app/welcome')), 'welcome-bundle');
export const Home = r => require.ensure([], () => r(require('../app/home')), 'home-bundle');
export const Auth = r => require.ensure([], () => r(require('../app/auth')), 'auth-bundle');
export const Ngos = r => require.ensure([], () => r(require('../app/ngos')), 'ngos-bundle');
export const Runs = r => require.ensure([], () => r(require('../app/runs')), 'runs-bundle');
export const RunsView = r => require.ensure([], () => r(require('../app/runs/view')), 'runs-view-bundle');
export const Group = r => require.ensure([], () => r(require('../app/group')), 'group-bundle');
export const Profile = r => require.ensure([], () => r(require('../app/profile')), 'profile-bundle');
export const History = r => require.ensure([], () => r(require('../app/history')), 'history-bundle');
export const Faq = r => require.ensure([], () => r(require('../app/faq')), 'faq-bundle');
export const FaqView = r => require.ensure([], () => r(require('../app/faq/view')), 'faq-view-bundle');
export const Phone = r => require.ensure([], () => r(require('../app/phone')), 'phone-bundle');

// admin
export const Admin = r => require.ensure([], () => r(require('../app/admin')), 'admin-bundle');
export const AdminRuns = r => require.ensure([], () => r(require('../app/admin/runs')), 'admin-bundle');
export const AdminVolunteers = r => require.ensure([], () => r(require('../app/admin/volunteers')), 'admin-bundle');

// ngo
export const Ngo = r => require.ensure([], () => r(require('../app/ngo')), 'ngo-bundle');
export const NgoRuns = r => require.ensure([], () => r(require('../app/ngo/runs')), 'ngo-bundle');
export const NgoDropOffs = r => require.ensure([], () => r(require('../app/ngo/drop-offs')), 'ngo-bundle');
export const NgoVolunteers = r => require.ensure([], () => r(require('../app/ngo/volunteers')), 'ngo-bundle');

// donor
export const Donor = r => require.ensure([], () => r(require('../app/donor')), 'donor-bundle');
export const DonorAuth = r => require.ensure([], () => r(require('../app/donor/auth')), 'donor-auth-bundle');
export const DonorWelcome = r => require.ensure([], () => r(require('../app/donor/welcome')), 'donor-welcome-bundle');
export const DonorEstimation = r => require.ensure([], () => r(require('../app/donor/estimation')), 'donor-estimation-bundle');

export const Privacy = r => require.ensure([], () => r(require('../app/pages/privacy')), 'privacy-bundle');

Vue.use(VueRouter);

const routes = [
  {
    path: '/welcome',
    name: 'welcome',
    component: Welcome,
    meta: {
      middleware: [guest],
      footer: true
    }
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      middleware: [auth, notDonor, notNgo, notAdmin, notGroup],
      footer: false
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    meta: {
      middleware: [guest],
      footer: false
    }
  },
  {
    path: '/ngos',
    name: 'ngos',
    component: Ngos,
    meta: {
      middleware: [auth, notDonor, notAdmin, notGroup],
      footer: false
    }
  },
  {
    path: '/phone',
    name: 'phone',
    component: Phone,
    meta: {
      middleware: [auth, notDonor, notAdmin, notGroup],
      footer: false
    }
  },
  {
    path: '/runs',
    name: 'runs',
    component: Runs,
    meta: {
      title: 'runs',
      footer: true,
      middleware: [auth, notDonor, notAdmin, notNgo, notGroup]
    }
  },
  {
    path: '/runs/:id',
    name: 'runs.view',
    component: RunsView,
    meta: {
      middleware: [auth],
      title: false,
      footer: false
    }
  },
  {
    path: '/group/:id',
    name: 'group',
    component: Group,
    meta: {
      title: false,
      footer: false
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      middleware: [auth, notDonor, notGroup],
      title: 'me',
      footer: true
    }
  },
  {
    path: '/history',
    name: 'history',
    component: History,
    meta: {
      middleware: [auth, notDonor, notGroup],
      title: 'history',
      footer: true
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    meta: {
      title: 'faq',
      footer: true
    },
    children: [{
      path: ':id',
      name: 'faq.view',
      component: FaqView,
      meta: {
        title: 'Detail',
        footer: false
      }
    }]
  },
  {
    path: '/ngo',
    name: 'ngo',
    component: Ngo,
    redirect: '/ngo/runs',
    meta: {
      middleware: [ngo],
      title: 'Our runs'
    },
    children: [
      {
        path: 'runs',
        name: 'ngo.runs',
        component: NgoRuns,
        meta: {
          middleware: [ngo],
          title: 'Runs'
        }
      },
      {
        path: 'drop-offs',
        name: 'ngo.drop-offs',
        component: NgoDropOffs,
        meta: {
          middleware: [ngo],
          title: 'Drop offs'
        }
      },
      {
        path: 'volunteers',
        name: 'ngo.volunteers',
        component: NgoVolunteers,
        meta: {
          middleware: [ngo],
          title: 'Volunteers'
        }
      }
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    redirect: '/admin/runs',
    meta: {
      middleware: [auth, admin],
      title: 'Admin'
    },
    children: [
      {
        path: 'runs',
        name: 'admin.runs',
        component: AdminRuns,
        meta: {
          middleware: [auth, admin],
          title: 'Runs'
        }
      },
      {
        path: 'volunteers',
        name: 'admin.volunteers',
        component: AdminVolunteers,
        meta: {
          middleware: [auth, admin],
          title: 'Volunteers'
        }
      }
    ]
  },
  {
    path: '/donors',
    name: 'donor',
    component: Donor,
    redirect: '/donors/auth',
    meta: {
      middleware: [donor],
      footer: false
    },
    children: [
      {
        path: 'auth',
        name: 'donor.auth',
        component: DonorAuth,
        meta: {
          middleware: [guest],
          footer: false
        }
      },
      {
        path: 'welcome',
        name: 'donor.welcome',
        component: DonorWelcome,
        meta: {
          middleware: [donor],
          footer: false
        }
      },
      {
        path: 'estimation',
        name: 'donor.estimation',
        component: DonorEstimation,
        meta: {
          middleware: [donor],
          footer: false
        }
      }
    ]
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
    meta: {
      title: 'Privacy Policy',
      footer: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(beforeEach);

export default router;
