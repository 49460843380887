import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const get = (params) => http.get('runs', { params }).then(getData);

/**
 * Get up to three nearby available runs based on a set of donor locations
 *
 * @returns {Promise<any> | PromiseLike<any>}
 * @param runs
 * @param filters
 */
export const nearby = (runs, filters) => {
  let locations = runs.map(run => {
    return {
      id: run.donor.id,
      latitude: run.donor.latitude,
      longitude: run.donor.longitude
    };
  });
  return http.post('runs/nearby', { locations }, { params: { ...filters } }).then(getData);
};

export const view = (id) => http.get('runs/' + id).then(getData);

export const update = (id, data) => http.patch(`runs/${id}`, data).then(getData);

export const claim = (id) => http.put(`runs/${id}/claim`).then(getData);

export const unclaim = (id) => http.delete(`runs/${id}/claim`).then(getData);
