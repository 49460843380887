<template>
  <v-app id="app" data-app>
    <v-main>
      <navbar v-if="$route.meta.title" :class="{'detail-navbar':$route.name === 'runs.view'}" />
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view class="page"/>
      </transition>
      <footer-ngo v-if="$store.getters.isNgo && isLogged" />
      <footer-volunteer v-else-if="$route.meta.footer && ($store.getters.isVolunteer || $store.getters.isLeader)" />
      <footer-admin v-else-if="isLogged && $store.getters.isAdmin" />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from './components/navbar';
import FooterVolunteer from './components/footer-volunteer';
import FooterNgo from './components/footer-ngo';
import FooterAdmin from '@/components/footer-admin.vue';

export default {
  data () {
    return {
      footerNavbar: true
    };
  },
  metaInfo2: {
    titleTemplate: '%s | Breadline',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Breadline'
      }
    ]
  },

  metaInfo () {
    return {
      titleTemplate: '%s | Breadline',
      meta: []
    };
  },
  created () {

  },
  components: {
    Navbar,
    FooterVolunteer,
    FooterNgo,
    FooterAdmin
  }
};
</script>

<style lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
</style>
