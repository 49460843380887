import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

/**
 * Drop-off points an NGO registered with Breadline
 *
 * @returns {Promise<any> | PromiseLike<any>}
 */
export const get = () => http.get('/dropoffs').then(getData);

/**
 * Schedule of drop-off points for the coming 3 months.
 *
 * @param id
 * @returns {Promise<any> | PromiseLike<any>}
 */
export const schedules = (id) => http.get('dropoffs/schedule').then(getData);

/**
 * Register a NGO drop-off point for a upcoming run date
 * @returns {Promise<T>}
 * @param params
 */
export const updateSchedule = (params) => {
  return http.put('dropoffs/schedule', params).then(getData);
};
