<script>
export default {
  metaInfo: {
    title: 'Logout'
  },
  beforeCreate () {
    this.$store.dispatch('logout');
  }
};
</script>
