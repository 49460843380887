<template>
  <v-toolbar
    class="custom-toolbar pr-2"
    color="secondary"
    flat
    dark
    dense
    :class="{'answer':answers}"
    :height="$route.name === 'faq.view' ? '110' : '50'"
  >
    <v-btn
      v-if="!isRunRemoving"
      @click="goBack"
      class="back-link"
      icon
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn
      v-if="isRunRemoving"
      class="close-link"
      font-weight-bold
      color="primary"
      @click="$store.commit('setRemovingRuns', false)"
      icon
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-toolbar-title v-if="$store.state.selectedRuns.length">
      {{ $t("navbar.delete") }} <span class="primary--text font-weight-bold">{{ $store.state.selectedRuns.length }}</span>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-toolbar-title v-if="$route.name !== 'faq.view' && $route.name !== 'home' && !isRunRemoving" >
      {{ $t(`footer.${$route.meta.title}`) }}
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn
      v-if="!isRunRemoving && $route.name === 'runs' && ($store.getters.isVolunteer || $store.getters.isLeader)"
      class="bin-link"
      @click="$store.commit('setRemovingRuns')"
      icon
    >
      <img src="/images/delete.svg">
    </v-btn>

    <v-badge
      v-if="$route.name === 'runs.add' && $store.state.addingRuns.length"
      :content="$store.state.addingRuns.length"
      color="aqua"
      overlap
    ></v-badge>

    <div v-if="answers" class="detail-head">
      <div class="sub-title">{{ $t("footer.faq") }}</div>
      <h3 class="detail-title">{{ $t(answers.name) }}</h3>
    </div>
  </v-toolbar>
</template>

<script>
export default {
  data () {
    return {};
  },
  computed: {
    isRunRemoving () {
      return this.$store.getters.isRunRemoving;
    },
    answers () {
      if (this.$route.name === 'faq.view') {
        return this.$store.state.faq.all.find(faq => faq.id === this.$route.params.id);
      } else {
        return false;
      }
    }
  },
  methods: {
    goBack () {
      if (this.$route.name === 'runs.add' || this.$route.name === 'home') {
        this.$bus.$emit('prev-step');
      } else {
        this.$router.go(-1);
      }
    }

  }
};
</script>
