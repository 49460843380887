import * as runService from '@/services/run';

export default {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    async get ({ commit, state }, params) {
      commit('setRuns', await runService.get(params));
    }
  },
  mutations: {
    setRuns (state, donors) {
      state.all = donors;
    }
  },
  getters: {
    count (state) {
      return state.all.count;
    }
  }
};
