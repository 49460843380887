export default {
  initialized: false,
  fetching: false,
  auth: {
    isAuthorized: false,
    token: localStorage.getItem('auth.token') || null,
    user: {},
    ngos: [],
    runs: [],
    stats: {}
  },
  runStatuses: [
    {
      title: 'All',
      value: null
    },
    {
      title: 'Unclaimed',
      value: 'unclaimed'
    },
    {
      title: 'Claimed',
      value: 'claimed'
    },
    {
      title: 'Completed',
      value: 'completed'
    }
  ],
  removingRuns: false,
  addingRuns: [],
  selectedRuns: []
};
