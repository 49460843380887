export default {
  namespaced: true,
  state: {
    all: [
      {
        id: '1',
        name: 'faq.one.title',
        items: [
          {
            title: 'faq.one.q1',
            text: 'faq.one.a1'
          },
          {
            title: 'faq.one.q2',
            text: 'faq.one.a2'
          },
          {
            title: 'faq.one.q3',
            text: 'faq.one.a3'
          }
        ]
      },
      {
        id: '2',
        name: 'faq.two.title',
        items: [
          {
            title: 'faq.two.q1',
            text: 'faq.two.a1'
          },
          {
            title: 'faq.two.q2',
            text: 'faq.two.a2'
          },
          {
            title: 'faq.two.q3',
            text: 'faq.two.a3'
          },
          {
            title: 'faq.two.q4',
            text: 'faq.two.a4'
          },
          {
            title: 'faq.two.q5',
            text: 'faq.two.a5'
          },
          {
            title: 'faq.two.q6',
            text: 'faq.two.a6'
          }
        ]
      },
      {
        id: '3',
        name: 'faq.three.title',
        items: [
          {
            title: 'faq.three.q1',
            text: 'faq.three.a1'
          },
          {
            title: 'faq.three.q2',
            text: 'faq.three.a2'
          },
          {
            title: 'faq.three.q3',
            text: 'faq.three.a3'
          },
          {
            title: 'faq.three.q4',
            text: 'faq.three.a4'
          },
          {
            title: 'faq.three.q5',
            text: 'faq.three.a5'
          },
          {
            title: 'faq.three.q6',
            text: 'faq.three.a6'
          }
        ]
      },
      {
        id: '4',
        name: 'faq.four.title',
        items: [
          {
            title: 'faq.four.q1',
            text: 'faq.four.a1'
          },
          {
            title: 'faq.four.q2',
            text: 'faq.four.a2'
          },
          {
            title: 'faq.four.q3',
            text: 'faq.four.a3'
          },
          {
            title: 'faq.four.q4',
            text: 'faq.four.a4'
          }
        ]
      },
      {
        id: '5',
        name: 'faq.five.title',
        items: [
          {
            title: 'faq.five.q1',
            text: 'faq.five.a1'
          },
          {
            title: 'faq.five.q2',
            text: 'faq.five.a2'
          },
          {
            title: 'faq.five.q3',
            text: 'faq.five.a3'
          },
          {
            title: 'faq.five.q4',
            text: 'faq.five.a4'
          },
          {
            title: 'faq.five.q5',
            text: 'faq.five.a5'
          },
          {
            title: 'faq.five.q6',
            text: 'faq.five.a6'
          },
          {
            title: 'faq.five.q7',
            text: 'faq.five.a7'
          },
          {
            title: 'faq.five.q8',
            text: 'faq.five.a8'
          }
        ]
      },
      {
        id: '6',
        name: 'faq.six.title',
        items: [
          {
            title: 'faq.six.q1',
            text: 'faq.six.a1'
          }
        ]
      },
      {
        id: '7',
        name: 'faq.seven.title',
        items: [
          {
            title: 'faq.seven.q1',
            text: 'faq.seven.a1'
          }
        ]
      }
    ]
  },
  actions: {

  },
  mutations: {
  },
  getters: {
  }
};
