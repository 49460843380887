import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import * as config from './config';

import './assets/scss/app.scss';
import store from './store';
import eventBus from './plugins/eventbus';
import httpPlugin from './plugins/http';
import globalMixin from './plugins/globalMixin';

import * as VueGoogleMaps from 'vue2-google-maps';
import VueSocialAuth from 'vue-social-auth';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment-timezone';
import Vue2TouchEvents from 'vue2-touch-events';
import VueMeta from 'vue-meta';
import SocialSharing from 'vue-social-sharing';

import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import i18n from './locales/i18n.js';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';

moment.tz.setDefault('Asia/Hong_Kong');

Vue.use(SocialSharing);
Vue.use(Vue2TouchEvents, { namespace: 'touchevents' });
Vue.use(eventBus);
Vue.use(require('vue-moment'), { moment });
Vue.use(Loading);
Vue.use(VueGoogleMaps, {
  load: {
    key: config.GOOGLE_API_KEY,
    libraries: 'places'
  }
});
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
Vue.directive('focus', {
  inserted: function (el) {
    el.focus();
  }
});
Vue.use(VuePlyr, {
  plyr: {
    youtube: {
      cc_load_policy: 1
    }
  }
});
Vue.use(VueSocialAuth, {
  providers: {
    google: {
      clientId: '587047898998-bo08lsovs4gn7hen0bpteuee7bc5vu2m.apps.googleusercontent.com',
      url: '/auth/google/callback',
      redirectUri: location.origin + '/auth'
    }
  },
  responseParams: {
    code: 'code',
    clientId: 'clientId',
    redirectUri: 'redirectUri'
  }
});
Vue.use(httpPlugin, { store, router });
Vue.mixin(globalMixin);

Vue.config.productionTip = false;

export default new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
