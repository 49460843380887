<template>
  <v-bottom-navigation
    v-model="bottomNav"
    :active-class="null"
    background-color="#FBE8DC"
    :fixed="true"
    color="secondary"
    :input-value="!isRunRemoving"
    class="footer-navbar justify-space-between"
    height="65"
    :class="{'removing':isRunRemoving}"
  >
    <v-btn exact to="/runs" :ripple="false" depressed>
      <img src="/images/my-runs.svg">
      <span>{{ $t("footer.runs")}}</span>
      <div
        v-if="runsCount"
        class="badge aqua"
      >
        {{ runsCount }}
      </div>
    </v-btn>

    <v-btn to="/history" :ripple="false" depressed>
      <img src="/images/history.svg">
      <span> {{ $t("footer.history")}} </span>
    </v-btn>

    <v-btn class="menu-btn" color="primary" width="62" height="62" fab v-if="isRunRemoving" @click="dialog = true">
      <img src="/images/delete-white.svg">
    </v-btn>
    <v-btn class="menu-btn" color="primary" width="62" height="62" fab v-else-if="!isHome" @click="$router.push({name: 'home'})">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-btn v-else-if="!selectedRun.id" class="menu-btn" color="#0A3F60" width="62" height="62" fab @click="$emit('commit-run')">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-btn v-else-if="selectedRun.id" class="menu-btn" color="primary" width="62" height="62" fab @click="$emit('commit-run')">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-btn depressed to="/faq" :ripple="false" exact>
      <img src="/images/how-to.svg">
      <span>{{ $t("footer.faq")}}</span>
    </v-btn>

    <v-btn depressed small to="/profile" :ripple="false">
      <img src="/images/me.svg">
      <span>{{ $t("footer.me")}}</span>
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="515"
      content-class="delete-dialog"
    >
      <v-card>
        <v-card-title class="primary--text delete-title">
          <div><strong> {{ $t("runview.sure") }} </strong></div>
        </v-card-title>
        <v-card-text class="secondary--text text-center">
          <div>
            {{ $t("navbar.are") }}
            {{ $t("navbar.replacement") }}
          </div>
          <div> {{ $t("navbar.case") }} </div>
          <br />
          <span>{{ $t("navbar.delete") }} &nbsp;<strong>{{ $store.state.selectedRuns.length }}</strong> {{ $t("navbar.runs") }} </span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="modal-btn cancel"
            text
            @click="dialog = false"
          >
            {{ $t("rundate.cancel") }}
          </v-btn>

          <v-btn
            class="modal-btn ok"
            text
            @click="deleteRuns"
          >
            {{ $t("rundate.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-bottom-navigation>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      runs: [],
      bottomNav: null
    };
  },
  mounted () {
    this.$bus.$on('runs-deleted', (runs) => {
      this.runs = runs;
    });
  },
  props: ['homescreen', 'selectedRun'],
  computed: {
    isRunRemoving () {
      return this.$store.getters.isRunRemoving;
    },
    runsCount () {
      return this.$store.getters.todayRuns.filter(run => !run.collectedAmount).length;
    },
    isHome () {
      return this.$route.name === 'home';
    }
  },
  methods: {
    deleteRuns () {
      this.$bus.$emit('runs-deleted');
      this.dialog = false;
    }
  },
  beforeDestroy () {
    this.$bus.$off('runs-deleted');
  }
};
</script>
