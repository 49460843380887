import Vue from 'vue';
import Vuex from 'vuex';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

import state from './state';
import donors from './modules/donors';
import ngos from './modules/ngos';
import dropoffs from './modules/dropoffs';
import runs from './modules/runs';
import faq from './modules/faq';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    donors,
    ngos,
    dropoffs,
    runs,
    faq
  },
  strict: debug
});
