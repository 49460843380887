export const setWelcomeCompleted = (state) => {
  state.auth.user.welcomeCompleted = true;
};
export const setRemovingRuns = (state, payload = true) => {
  state.removingRuns = payload;
};
export const setAddingRuns = (state, runs) => {
  state.addingRuns = runs;
};
export const setSelectedRuns = (state, payload) => {
  state.selectedRuns = payload;
};
export const setToken = (state, value) => {
  state.auth.token = value;
};
export const setUser = (state, value) => {
  state.auth.user = value;
  state.auth.isAuthorized = true;
};
export const setNgos = (state, value) => {
  state.auth.ngos = value;
};
export const setRuns = (state, value) => {
  state.auth.runs = value;
};
export const setStats = (state, value) => {
  state.auth.stats = value;
};
export const setInitialized = (state) => {
  state.initialized = true;
};

export const logout = (state) => {
  state.auth.user = {};
  state.auth.token = null;
  state.auth.isAuthorized = false;
};
